import { DEFAULT_PROJECT_ID } from '@unifyapps/network/utils/consts/project';

export const PROJECT_DETAILS_PREFIX = '/p';
const ENABLED_PROJECT_ROUTES = [
  '/automations',
  '/automation-interfaces',
  '/api-platform',
  '/pipelines',
  '/interfaces',
  '/object',
  '/objects',
  '/campaigns',
  '/segments',
  '/connections',
  '/templates',
  '/env-variables',
  '/event-streams',
  '/decision-tables',
];

export const getAdaptedProjectsRoute = (
  route: string,
  projectSlug: string | undefined | null | number,
) => {
  if (route.startsWith(`${PROJECT_DETAILS_PREFIX}/`)) {
    return route;
  }

  const shouldPrefix = ENABLED_PROJECT_ROUTES.some((r) => route.startsWith(r));

  if (!shouldPrefix) {
    return route;
  }

  return `${PROJECT_DETAILS_PREFIX}/${projectSlug || DEFAULT_PROJECT_ID}${route}`;
};
