'use client';

import React, { useEffect } from 'react';
import { useGetApiUserContext } from '@unifyapps/network/generated/user-context-rest-api/user-context-rest-api';
import { useChangeLanguage } from '@unifyapps/i18n/client';
import _map from 'lodash/map';
import _set from 'lodash/set';
import { setUserContext } from '../../utils/error-reporting/user';
import userStore from '../userStore';
import { useGetUserRoleNames } from '../../hooks/useGetUserRoleNames';

function AuthProvider({
  isLoggedIn,
  children,
}: {
  isLoggedIn?: string;
  children: React.ReactNode;
}) {
  const { data } = useGetApiUserContext({
    query: { enabled: Boolean(isLoggedIn) },
  });

  const { userRolesName, isRolesLoading } = useGetUserRoleNames(data?.user?.roleIds);
  const setCurrentUserDetails = userStore.use.actions().setCurrentUserDetails;
  const locale = data?.user?.customProperties?.locale as string | undefined;
  const setLanguage = useChangeLanguage();

  useEffect(() => {
    if (!locale) return;
    setLanguage(locale);
  }, [locale, setLanguage]);

  useEffect(() => {
    if (data && !isRolesLoading) {
      const adaptedData = _set(
        data,
        'user.roles',
        _map(data.user?.roleIds, (roleId, index) => ({
          id: roleId,
          name: userRolesName[index],
        })),
      );

      setUserContext(adaptedData);
      setCurrentUserDetails(adaptedData);
    }
  }, [data, isRolesLoading, setCurrentUserDetails, userRolesName]);

  return <>{children}</>;
}

export default AuthProvider;
