export enum ProjectWorkspaceSidebarTab {
  Overview = 'overview',
  Automations = 'automations',
  APIPlatform = 'api-platform',
  AutomationInterfaces = 'automation-interfaces',
  Insights = 'insights',
  Pipelines = 'pipelines',
  Interfaces = 'interfaces',
  Objects = 'objects',
  Campaigns = 'campaigns',
  Segments = 'segments',
  Connections = 'connections',
  Templates = 'templates',
  EnvironmentVariables = 'env-variables',
  EventStreams = 'event-streams',
}
