import { useMemo } from 'react';
import type { NavItems } from '@unifyapps/carbon/components/SideNav/types';
import { getEnabledGroups } from '@unifyapps/carbon/components/SideNav/utils/getEnabledGroups';
import getEnabledItems from '@unifyapps/carbon/utils/getEnabledItems';
import { Modules } from '@unifyapps/carbon/consts/modules';
import _noop from 'lodash/noop';
import userStore from '@unifyapps/carbon/auth/userStore';
import { getDisplayName } from '@/utils/modules/getDisplayName';
import { useGetModulesMap } from '@/hook/useGetSideNavDetails';
import useModulesPermissions from '@/modules/sidenav/components/AppSideNav/useModulesPermissions';
import { showSettingsIcon } from '@/modules/settings/utils/showSettingsIcon';
import useModuleRoute from '@/modules/sidenav/hooks/useModuleRoute';

/**
 * Fallback sidenav items for when the Platform Navigation object is not configured
 */
export default function useFallbackSidenavItems() {
  const data = userStore.use.currentUserDetails();
  const modules = data?.modules || undefined;
  const modulesMap = useGetModulesMap(modules);
  const permissionsObject = useModulesPermissions(modulesMap);
  const settingsPermission = showSettingsIcon(permissionsObject);
  const { getModuleRoute } = useModuleRoute();

  const primaryNavItems = useMemo<NavItems>(() => {
    return getEnabledGroups([
      {
        type: 'GROUP',
        id: 'projects',
        name: 'Projects',
        icon: 'LayersThree02',
        items: getEnabledItems([
          {
            type: 'URL',
            id: Modules.Project,
            name: getDisplayName(Modules.Project, 'Projects', modulesMap),
            icon: 'LayersThree02',
            url: getModuleRoute(Modules.Project),
            enabled: permissionsObject[Modules.Project],
          },
        ]),
      },
      {
        type: 'GROUP',
        id: 'workflow',
        name: 'Workflows',
        icon: 'Zap',
        items: getEnabledItems([
          {
            type: 'URL',
            id: Modules.WorkflowDefinition,
            name: getDisplayName(Modules.WorkflowDefinition, 'Automations', modulesMap),
            icon: 'List',
            url: getModuleRoute(Modules.WorkflowDefinition),
            enabled: permissionsObject[Modules.WorkflowDefinition],
          },
          {
            type: 'URL',
            id: Modules.WorkflowLibrary,
            name: getDisplayName(Modules.WorkflowLibrary, 'Template Library'),
            icon: 'FileShield02',
            url: getModuleRoute(Modules.WorkflowLibrary),
            enabled: permissionsObject[Modules.WorkflowLibrary],
          },
          {
            type: 'URL',
            id: Modules.CallableInterface,
            name: getDisplayName(Modules.CallableInterface, 'Automation Interfaces', modulesMap),
            icon: 'LayoutAlt04',
            url: getModuleRoute(Modules.CallableInterface),
            enabled: permissionsObject[Modules.CallableInterface],
          },
        ]),
      },
      {
        type: 'GROUP',
        id: 'data',
        name: 'Data',
        icon: 'ArrowsRight',
        items: getEnabledItems([
          {
            type: 'URL',
            id: Modules.PipelineConfig,
            name: getDisplayName(Modules.PipelineConfig, 'Pipelines', modulesMap),
            icon: 'ArrowsRight',
            url: getModuleRoute(Modules.PipelineConfig),
            enabled: permissionsObject[Modules.PipelineConfig],
          },
          {
            type: 'URL',
            id: Modules.data_catalog,
            name: getDisplayName(Modules.data_catalog, 'Data Catalog', modulesMap),
            icon: 'Server04',
            url: getModuleRoute(Modules.data_catalog),
            enabled: permissionsObject[Modules.data_catalog],
          },
          {
            type: 'URL',
            id: Modules.UnifiedEntityModel,
            name: getDisplayName(Modules.UnifiedEntityModel, 'Unified Entity Model', modulesMap),
            icon: 'Dataflow04',
            url: getModuleRoute(Modules.UnifiedEntityModel),
            enabled: permissionsObject[Modules.UnifiedEntityModel],
          },
        ]),
      },
      {
        type: 'GROUP',
        id: 'unify-ai',
        name: 'Unify AI',
        icon: 'Stars02',
        items: getEnabledItems([
          {
            type: 'URL',
            id: Modules.ai_agent,
            name: 'AI Agents',
            icon: 'Atom02',
            url: getModuleRoute(Modules.ai_agent),
            enabled: permissionsObject[Modules.ai_agent],
          },
          // {
          //   type: 'URL',
          //   id: Modules.AIModels,
          //   name: 'Foundation Models',
          //   url: getModuleRoute(Modules.AIModels),
          //   enabled: permissionsObject[Modules.ai_agent], // TODO
          // },
          // {
          //   type: 'URL',
          //   id: Modules.CustomModels,
          //   name: 'Custom Models',
          //   url: getModuleRoute(Modules.CustomModels),
          //   enabled: permissionsObject[Modules.ai_agent], // TODO
          // },
          {
            type: 'URL',
            id: Modules.Copilot,
            name: getDisplayName(Modules.Copilot, 'Copilot', modulesMap),
            icon: 'Stars02',
            url: getModuleRoute(Modules.Copilot),
            enabled: permissionsObject[Modules.Copilot],
          },
        ]),
      },
      {
        type: 'GROUP',
        id: 'applications',
        name: 'Applications',
        icon: 'LayoutAlt01',
        items: getEnabledItems([
          {
            type: 'URL',
            id: Modules.NoCodeInterface,
            name: getDisplayName(Modules.NoCodeInterface, 'Applications', modulesMap),
            icon: 'LayoutAlt01',
            url: getModuleRoute(Modules.NoCodeInterface),
          },
        ]),
        enabled: permissionsObject[Modules.NoCodeInterface],
      },
      {
        type: 'GROUP',
        id: 'api-manager',
        name: 'API Manager',
        icon: 'SvgUnifyApi',
        items: getEnabledItems([
          {
            type: 'URL',
            id: Modules.api_platform,
            name: getDisplayName(Modules.api_platform, 'API Manager', modulesMap),
            icon: 'SvgUnifyApi',
            url: getModuleRoute(Modules.api_platform),
          },
        ]),
        enabled: permissionsObject[Modules.APIEndpointCollection],
      },
      {
        type: 'GROUP',
        id: 'campaign',
        name: 'Composable CDP',
        icon: 'Announcement03',
        items: getEnabledItems([
          {
            type: 'URL',
            id: Modules.e_campaign,
            name: getDisplayName(Modules.e_campaign, 'Campaigns Manager', modulesMap),
            icon: 'User01',
            url: getModuleRoute(Modules.e_campaign),
            enabled: permissionsObject[Modules.e_campaign],
          },
          {
            type: 'URL',
            id: Modules.SegmentDefinition,
            name: getDisplayName(Modules.SegmentDefinition, 'Segments Manager', modulesMap),
            icon: 'PieChart01',
            url: getModuleRoute(Modules.SegmentDefinition),
            enabled: permissionsObject[Modules.SegmentDefinition],
          },
        ]),
      },
      {
        type: 'GROUP',
        id: 'objects',
        name: 'Objects',
        icon: 'SvgLayoutGrid02',
        items: [
          {
            type: 'URL',
            id: Modules.EntityType,
            name: getDisplayName(Modules.EntityType, 'Objects', modulesMap),
            icon: 'Settings01',
            url: getModuleRoute(Modules.EntityType),
            enabled: permissionsObject[Modules.EntityType],
          },
        ],
      },

      {
        type: 'GROUP',
        id: 'tools',
        name: 'Platform Tools',
        icon: 'SvgGrid01',
        items: getEnabledItems([
          {
            type: 'URL',
            id: Modules.Connection,
            name: getDisplayName(Modules.Connection, 'Connections Manager', modulesMap),
            icon: 'Route',
            url: getModuleRoute(Modules.Connection),
            enabled: permissionsObject[Modules.Connection],
          },
          {
            type: 'URL',
            id: Modules.ConnectorDefinition,
            name: getDisplayName(Modules.ConnectorDefinition, 'Connectors SDK', modulesMap),
            icon: 'CustomConnector01',
            url: getModuleRoute(Modules.ConnectorDefinition),
            enabled: permissionsObject[Modules.ConnectorDefinition],
          },
          {
            type: 'URL',
            id: Modules.EnvironmentVariables,
            name: getDisplayName(Modules.EnvironmentVariables, 'Environment Variables', modulesMap),
            icon: 'Loop',
            url: getModuleRoute(Modules.EnvironmentVariables),
            enabled: permissionsObject[Modules.EnvironmentVariables],
          },
          {
            type: 'URL',
            id: Modules.e_streams,
            name: getDisplayName(Modules.e_streams, 'Event Streams', modulesMap),
            icon: 'SvgSignal03',
            url: getModuleRoute(Modules.e_streams),
            enabled: permissionsObject[Modules.e_streams],
          },
          {
            type: 'URL',
            id: Modules.decision_tables,
            name: getDisplayName(Modules.decision_tables, 'Decision Tables', modulesMap),
            icon: 'Table',
            url: getModuleRoute(Modules.decision_tables),
            enabled: permissionsObject[Modules.decision_tables],
          },
          {
            type: 'URL',
            id: Modules.e_template,
            name: getDisplayName(Modules.e_template, 'Templates Manager', modulesMap),
            icon: 'FileShield02',
            url: getModuleRoute(Modules.e_template),
            enabled: permissionsObject[Modules.e_template],
          },
        ]),
      },
    ]) as NavItems;
  }, [getModuleRoute, modulesMap, permissionsObject]);

  const secondaryNavItems = useMemo<NavItems>(
    () =>
      getEnabledItems([
        // {
        //   type: 'URL',
        //   id: 'help',
        //   name: 'Help',
        //   icon: 'HelpCircle',
        //   url: '/help',
        // },
        {
          type: 'ACTION',
          id: Modules.Notification,
          name: 'Notifications',
          icon: 'SvgNotification',
          enabled: permissionsObject[Modules.Notification],
          onClick: _noop,
          action: 'showNotifications',
        },
        {
          type: 'URL',
          id: Modules.Settings,
          name: 'Settings',
          icon: 'Settings01',
          url: getModuleRoute(Modules.Settings) ?? '',
          enabled: settingsPermission,
        },
        {
          type: 'USER_MENU',
          id: Modules.UserMenu,
          enabled: true,
          name: 'User',
          user: {},
          MenuList: () => null,
        },
      ]),
    [getModuleRoute, permissionsObject, settingsPermission],
  );

  return {
    primaryNavItems,
    secondaryNavItems,
  };
}
