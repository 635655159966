export const BASE_ROUTE = '/api-platform';

export const getApiDashboardRoute = () => `${BASE_ROUTE}/dashboard`;

// collections

export const getCollectionsRoute = () => `${BASE_ROUTE}/collections`;

export const getCreateCollectionRoute = () => `${BASE_ROUTE}/collections/create`;

export const getEditCollectionDetailsRoute = (collectionId: string) =>
  `${BASE_ROUTE}/collections/${collectionId}`;

export const getEditCollectionRoute = (collectionId: string) =>
  `${BASE_ROUTE}/collections/edit/${collectionId}`;

export const getShareCollectionRoute = (collectionId: string) =>
  `${BASE_ROUTE}/collections/share/${collectionId}`;

export const getCollectionClientsRoute = (collectionId: string) =>
  `${BASE_ROUTE}/collections/${collectionId}/clients`;

// end points

export const getEndpointsRoute = (collectionId: string) =>
  `${BASE_ROUTE}/collections/${collectionId}/endpoints`;

export const getShareCollectionFromEndpointRoute = (collectionId: string) =>
  `${BASE_ROUTE}/collections/${collectionId}/endpoints/share`;

export const getCreateEndpointRoute = (collectionId: string) =>
  `${BASE_ROUTE}/collections/${collectionId}/endpoints/create`;

export const getEndpointDetailsRoute = (collectionId: string, endpointId: string) =>
  `${BASE_ROUTE}/collections/${collectionId}/endpoints/${endpointId}`;

export const getEditEndpointRoute = (collectionId: string, endpointId: string) =>
  `${BASE_ROUTE}/collections/${collectionId}/endpoints/edit/${endpointId}`;

// policies

export const getPoliciesRoute = () => `${BASE_ROUTE}/policies`;

export const getCreatePolicyRoute = () => `${BASE_ROUTE}/policies/create`;

export const getEditPolicyRoute = (policyId: string) => `${BASE_ROUTE}/policies/edit/${policyId}`;

export const getSharePolicyRoute = (policyId: string) => `${BASE_ROUTE}/policies/share/${policyId}`;

// clients

export const getClientsRoute = () => `${BASE_ROUTE}/clients`;

export const getClientDetailsRoute = (clientId: string) => `${BASE_ROUTE}/clients/${clientId}`;

export const getCreateClientRoute = () => `${BASE_ROUTE}/clients/create`;

export const getEditClientRoute = (clientId: string) => `${BASE_ROUTE}/clients/edit/${clientId}`;

export const getShareClientRoute = (clientId: string) => `${BASE_ROUTE}/clients/share/${clientId}`;

// access profiles

export const getCreateAccessProfileRoute = (clientId: string) =>
  `${BASE_ROUTE}/clients/${clientId}/details/create`;

export const getEditAccessProfileRoute = (clientId: string, accessProfileId: string) =>
  `${BASE_ROUTE}/clients/${clientId}/details/edit/${accessProfileId}`;
