const DECISION_TABLES_BASE_ROUTE = '/decision-tables';

export const getDecisionTablesManagerRoute = () => DECISION_TABLES_BASE_ROUTE;

export const getDecisionTableDetailsRoute = (decisionTableId: string) =>
  `${DECISION_TABLES_BASE_ROUTE}/${decisionTableId}`;

export const getVariablesTabRoute = (decisionTableId: string) =>
  `${DECISION_TABLES_BASE_ROUTE}/${decisionTableId}/variables`;

export const getDecisionTableTabRoute = (decisionTableId: string) =>
  `${DECISION_TABLES_BASE_ROUTE}/${decisionTableId}/decision-table`;

export const getCreateDecisionTableRoute = () => `${DECISION_TABLES_BASE_ROUTE}/create`;

export const getEditDecisionTableRoute = (decisionTableId: string) =>
  `${DECISION_TABLES_BASE_ROUTE}/edit/${decisionTableId}`;

export const getShareDecisionTableRoute = (decisionTableId: string) =>
  `${DECISION_TABLES_BASE_ROUTE}/share/${decisionTableId}`;
