import { DEFAULT_PROJECT_ID } from '@unifyapps/network/utils/consts/project';
import { PipeLineTabs } from '../views/PipelineDetails/types';

export const PIPELINE_BASE_ROUTE = '/pipelines';
export const DEFAULT_PIPELINE_TAB = PipeLineTabs.SourceAndDestination;

export function getPipelineTabRoute(
  pipelineId: string,
  tabId: PipeLineTabs = DEFAULT_PIPELINE_TAB,
) {
  return `${PIPELINE_BASE_ROUTE}/${pipelineId}/${tabId}`;
}

export const getInsightsRoute = () => `${PIPELINE_BASE_ROUTE}/insights`;
export const getAlertsRoute = () => `${PIPELINE_BASE_ROUTE}/alerts`;

export function getPipelineCreationRoute() {
  return `${PIPELINE_BASE_ROUTE}/create`;
}

export function getPipelineLogDetailsRoute(pipelineId: string) {
  return `${getPipelineTabRoute(pipelineId, PipeLineTabs.Logs)}/details`;
}

export function getPipelineFailedLogDetailsRoute(
  pipelineId: string,
  objectId: string,
  logId: string,
) {
  return `${getPipelineTabRoute(pipelineId, PipeLineTabs.Logs)}/${objectId}/details/${logId}`;
}

// TODO - We need to handle this another way.
export function getPipelineManagerRoute() {
  return `/p/${DEFAULT_PROJECT_ID}/pipelines`;
}
